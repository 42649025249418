import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { BASE, IMG_URL } from "../../../configuration/apiconfig";
import { CurrencyDecimalFormat } from "../../helper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import proPic from '../../../assets/images/nophoto/no_photo.jpg';

function Newaccounts({ newacclist, lodervalue,acc_count }) {



  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  return (
    <div className={style.dashboard_new_member_sec}>
      <div
        className={`${currentTheme == "theme-dark"
          ? style.dark_mode_dashboard_pannel_box
          : style.dashboard_pannel_box
          }`}
      >
        {lodervalue ? (
          <div>
            <div
              className={`${currentTheme == "theme-dark"
                ? style.dark_mode_dashboard_new_member_head
                : style.dashboard_new_member_head
                }`}
            >
              {" "}
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton width={150} count={1} />{" "}
              </SkeletonTheme>
            </div>
            <div className={style.dashboard_new_member_cnt_scrl}>
              {[1, 2, , 4].map((list, index) => {
                return (
                  <div className={style.dashboard_new_member_row} key={index}>
                    <div className={style.dashboard_new_member_sub_account}>
                      <div className={style.dashboard_new_member_name}>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={100} count={1} />
                        </SkeletonTheme>
                      </div>
                      <div className={style.dashboard_new_member_amnt}>
                        {" "}
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={50} count={1} />{" "}
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className={style.dashboard_new_member_sub_account}>
                      <div className={style.dashboard_new_member_id_date}>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={50} count={1} />
                        </SkeletonTheme>
                      </div>
                      <div className={style.dashboard_new_member_date_sc}>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={30} count={1} />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (<>
          {newacclist && (
            <div>
              <div
                className={`${currentTheme == "theme-dark"
                  ? style.dark_mode_dashboard_new_member_head
                  : style.dashboard_new_member_head
                  }`}
              >
                {t("Dashboard.newsubacc")}


                {
                  acc_count &&
                  <div style={{
                    "float": "right",
                    "fontSize": "10px"
                  }}

                  >Total number of sub accounts is {acc_count}
                  </div>
                }







              </div>

              <div className={style.dashboard_new_member_cnt_scrl}>
                {newacclist.length > 0 ? (
                  newacclist.map((list, index) => {
                    return (
                      <div className={style.dashboard_new_member_row} key={index}>
                        <div className={style.dashboard_new_member_sub_account}>
                          <img
                            className={`${currentTheme == "theme-dark"
                              ? style.dark_mode_dashboard_new_member_pic
                              : style.dashboard_new_member_pic
                              }`}
                            alt=""
                            src={list.image.length === 0 ? proPic : list.image}
                          />

                          <div className={`${currentTheme == "theme-dark"
                            ? style.dark_mode_dashboard_newsubacc_wrap
                            : style.dashboard_newsubacc_wrap
                            }`}>

                            <div
                              className={`${currentTheme == "theme-dark"
                                ? style.dark_mode_dashboard_new_member_name
                                : style.dashboard_new_member_name
                                }`}
                            >
                              {list.username}
                            </div>

                            <div
                              className={`${currentTheme == "theme-dark"
                                ? style.dark_mode_dashboard_new_member_id_date
                                : style.dashboard_new_member_id_date
                                }`}
                            >
                              {list.createdAt}
                            </div>


                          </div>


                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className={`${style.NodataImage}`}
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={IMG_URL + "logos/no-datas-found.png"}
                      alt="nodata"
                      style={{ width: "50%" }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>)}

      </div>
    </div>
  );
}
export default Newaccounts;
