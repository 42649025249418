import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { Link } from "react-router-dom";
import Links from "./Links";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import proPic from '../../../assets/images/nophoto/no_photo.jpg'
import DataTable from "react-data-table-component";
import zoomlogo from "../../../assets/images/zoomlogo1.png"

const Zoomcall = (props) => {

  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();


  const ZoomColumns = [
    {
      name: <b>{'ID'}</b>,
      cell: row => `${row.id}`
    },
    {
      name: <b>{'JOIN URL'}</b>,
      cell: row => <a href={`${row.join_url}`} target="_blank" rel="noreferrer" style={{ "fontSize": "smaller" }}> {`${row.join_url}`} </a>
    }, {
      name: <b>{'START TIME'}</b>,
      cell: row => `${row.Date}`
    },
    {
      name: <b>{'TOPIC'}</b>,
      cell: row => `${row.topic}`
    },
    // {
    //   name: <b>{'UUID'}</b>,
    //   cell: row => <div style={{"fontSize" : "smaller"}}>  {`${row.uuid}`} </div>
    // },
  ]


  return (
    <div className={style.dashboard_new_member_sec}>
      <div
        className={`${currentTheme == "theme-dark"
          ? style.dark_mode_dashboard_pannel_box
          : style.dashboard_pannel_box
          }`}
      >

        <div style={{"display" : "inline-flex"}}>
          <div className={style.zoom_main_title}>Zoom meetings</div>
          <img src={zoomlogo} style={{ "height": "30px", "width": "30px", "marginLeft": "10px", "marginTop": "-3px" }} />
        </div>


        <DataTable
          columns={ZoomColumns}
          data={props?.zoom_calls}
        />

      </div>


    </div>
  );
};
export default Zoomcall;
