import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class PartySetupService {
  key = localStorage.getItem("apiKey");
  async getTheHost() {
    const response = await fetch(`${BASE_URL}party/create_setup`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    const result = await response.json();
    return result;
  }
  async getTheGuest() {
    const response = await fetch(`${BASE_URL}party/allGuests`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    const result = await response.json();
    return result;
  }

  async AddGuest(form_data) {
    const response = await fetch(`${BASE_URL}party/createGuest`, {
      //url
      method: "POST", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
      body: JSON.stringify(form_data),
    });
    const result = await response.json();
    return result;
  }
  async viewGuest(id) {
    const response = await fetch(`${BASE_URL}party/updateGuest?id=${id}`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    const result = await response.json();
    return result;
  }
  async viewHost(id) {
    const response = await fetch(`${BASE_URL}party/updateHost?id=${id}`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    const result = await response.json();
    return result;
  }
  async AddHost(form_data) {
    const response = await fetch(`${BASE_URL}party/createHost`, {
      //url
      method: "POST", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
      body: JSON.stringify(form_data),
    });
    const result = await response.json();
    return result;
  }

  async deleteGuest(data) {
    const response = await fetch(`${BASE_URL}party/deleteGuest`, {
      //url
      method: "post", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  }
  async deleteHost(data) {
    const response = await fetch(`${BASE_URL}party/deleteHost`, {
      //url
      method: "post", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  }

  async getHost() {
    const response = await fetch(`${BASE_URL}party/allHosts`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    const result = await response.json();
    return result;
  }

  async getCountry() {
    const response = await fetch(`${BASE_URL}party/getAllCountry`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    const result = await response.json();
    return result;
  }
  async countryChange(countryID) {
    const response = await fetch(
      // `${BASE_URL}profile/countryChange?country_id=${countryID}`,
      `${BASE_URL}replica_register/countryChange?country_id=${countryID}`,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  async createParty(form_data) {
    const response = await fetch(`${BASE_URL}party/create_party`, {
      //url
      method: "POST", //method
      headers: {
        //passing header
        "api-key": this.key,
        "access-token": UserStore.key,
      },
      body: form_data,
    });
    const result = await response.json();
    return result;
  }
}
