import React, { useEffect, useState} from 'react';
import moment from 'moment'
import { IdleTimeOutModal } from './IdleTimeOutModal'
import {useHistory} from 'react-router-dom'


const IdleTimeOutHandlerCom = (props)=>{
    console.log("timtmtmt",props)
    const[showModal,setShowModal]=useState(false)
    const[isLogout,setLogout]=useState(false)
    let timer=undefined;
    const history = useHistory();
    const events= ['click','load','keydown','mousemove']
    const eventHandler =(eventType)=>{
        
        console.log(eventType)
        if(!isLogout){
            localStorage.setItem('lastInteractionTime',moment() )
            if(timer){
                props.onActive();
                startTimer();
            }
        }
        
    };
    
    useEffect(()=>{
        addEvents();
        
        return (()=>{
            
            removeEvents();
            clearTimeout(timer);
        })
    },[])
    
    const startTimer=()=>{
        
        if(timer){
            clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            
            let lastInteractionTime=localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval=props.time?props.time:60000;
            if(isLogout){
                clearTimeout(timer)
            }else{
                if(diff._milliseconds<timeOutInterval){
                    console.log("i aminside if")
                    startTimer();
                    props.onActive();
                }else{
                    console.log("i aminside else")
                    props.onIdle();
                    // setShowModal(true)
                   
                    localStorage.setItem("timeout", true)
                    history.push('/timeout')
                    //  history.push('/logout')
                }
            }
            
        },props.timeOutInterval?props.timeOutInterval:6000)
        
        
        
        
        
    }
    const addEvents=()=>{
        
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
        
        startTimer();
    }
    
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };
    
    // const handleContinueSession = ()=>{
    //     setShowModal(false)
    //     setLogout(false)
    // }
    // const handleLogout = ()=>{
    //     removeEvents();
    //     clearTimeout(timer);
    //     setLogout(true)
    //     props.onLogout();
    //     setShowModal(false)
        
    // }
    
    return(
        <div>
        
        {/* <IdleTimeOutModal 
        showModal={showModal} 
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
        /> */}
        
        </div>
        )
        
    }
    
    export default IdleTimeOutHandlerCom;