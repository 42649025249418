//local
// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.12.9:5010/node_api/";
// const IMG_URL = "http://192.168.12.9:5010/uploads/images/";
// const BASE = "http://192.168.12.9:5010/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// const DEFAULT_KEY = "745_";

//live
import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const BASE_URL = "https://admin.propositionsinternational.com/node_api/";
const IMG_URL =
  "https://admin.propositionsinternational.com/node_api/uploads/images/";
const BASE = "https://admin.propositionsinternational.com/node_api/";
const LEAD_URL = "https://admin.propositionsinternational.com/node_api/";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
const DEFAULT_KEY = "745_";

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://162.19.146.134:128/api/";
// const IMG_URL = "http://162.19.146.134:128/uploads/images/";
// const BASE = "http://162.19.146.134:128/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY='745_'
// // const DEFAULT_KEY='1055_'
// const DEFAULT_KEY='760_'

// //live
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://admin.propositionsinternational.com/node_api/";
// const IMG_URL = "https://admin.propositionsinternational.com/node_api/uploads/images/";
// const BASE = "https://admin.propositionsinternational.com/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/node_api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='760_'
export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
};
