import React, { useEffect } from "react";
import { AddonNotification } from "../common";
import style from "./crm.module.scss";
import { useTranslation } from "react-i18next";
import { ProgressBar, Tab, Tabs } from "react-bootstrap";
import LeadDetails from "./LeadDetails";
import LeadHistory from "./LeadHistory";
import AddFollowUp from "./AddFollowUp";
import CrmModal from "./CrmModal";
import AddForm from "./AddForm";
function CrmTimelineContent(props) {
  const { t } = useTranslation();
  useEffect(() => {
    document.getElementsByClassName("progress-bar")[0].style.backgroundColor =
      props.color;
  }, [props.color]);
  return (
    <div className={`${style.timeLineContent}`}>
      {/* <AddonNotification /> */}
      <ProgressBar
        animated
        now={props.lead_completeness}
        label={t("Crm.leadCompleatness", { value: props.lead_completeness })}
        className={`${style.progress} mb-3`}
      />
      <div className={`${style}`}>
        <Tabs defaultActiveKey="leadDetails" id="tab">
          <Tab eventKey="leadDetails" title={t("lead.leadDetails")}>
            <LeadDetails {...props.leadDetails} showModal={props.showModal} />
          </Tab>
          <Tab eventKey="banner_invites" title={t("lead.leadHistory")}>
            <LeadHistory
              followup={props.followup_history}
              lead_details={props.leadDetails}
              showModal={props.showModal}
              callback={props.callback}
            />
          </Tab>
        </Tabs>
      </div>
      <CrmModal
        show={props.modal.show}
        handleClose={props.handleClose}
        title={
          props.modal.title ? t(`Crm.${props.modal.title}`) : t("Crm.editLead")
        }
      >
        {props.modal.title === "edit" && (
          <AddForm
            data={props.modal.data}
            id={props.modal.id}
            BtnName="updateLead"
            isUpdate={true}
            submitCallback={props.submitCallback}
          />
        )}
        {props.modal.title === "addFollowup" && (
          <AddFollowUp
            id={props.leadDetails.id}
            name={props.leadDetails.first_name}
            callBack={props.callback}
          />
        )}
      </CrmModal>
    </div>
  );
}

export default CrmTimelineContent;
