import React, { useState, useEffect } from 'react';
import Helmet from "react-helmet";
import { COMPANY_NAME } from "../../../configuration/apiconfig";
import style from "../../components/auth/auth.module.scss";
import TimeoutCom from '../../components/autologout/TImeout';
import Forms from "../../components/auth/Forms";
import { Alert, Form } from "react-bootstrap";
import LoginButton from "../../components/auth/LoginButton";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { islogged } from "../../../store/action";
import LoginService from "../../../service/Auth/Login";
import UserStore from "../../../store/UserStore";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter, Redirect, useHistory } from "react-router-dom";
import proPic from '../../../assets/images/nophoto/no_photo.jpg'

import { logout } from '../../../store/action';

const mapStateToProps = (state) => {
    return { proPs: state };
  };

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ islogged }, dispatch);
  }
const Timeout = (proPs) => {

    // const[isActive,setIsActive]=useState(true)
    // const[isLogout,setLogout]=useState(false)

    // const { currentTheme } = useSelector((state) => state.Theme);
   

    const [state, setState] = useState({
        userName: localStorage.getItem("username") ? localStorage.getItem("username") : "",
        password: "",
        adminuser: localStorage.getItem("adminname") ? localStorage.getItem("adminname") : "",
        timeout: localStorage.getItem("timeout") ? localStorage.getItem("timeout") : false,
        isLoggedError: false,
        isLogged: false,
        unapproved: false,
        FormError: {
            userName: "",
            password: "",
            adminuser: "",
            captchavalue: ""
        },
        btnDisabled: false,
        loader: false,
        showCaptcha: false,
        captchavalue: ""
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();
  
    useEffect(() => {
       
        dispatch(logout());
        localStorage.removeItem('login');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('currency');



    }, [])

    const handleAlertDismiss = () => {
        setState((prevState) => ({
          ...prevState,
          isLoggedError: false,
        }));
      };

    const resetform = () => {
        document.getElementById("login-form").reset(); //reset form
        setState((prevState) => ({
          //reset state
          ...prevState,
          userName: "",
          password: "",
        }));
      };

    onchange = (e) => {
        e.preventDefault();
        const { name, value } = e.target; //get the field name
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            FormError: {
                [name]: "",
            },
        }));
    };
    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        switch (name) {
          case "userName":
            FormError.userName = value.length === 0 ? "required" : "";
            break;
          case "password":
            FormError.password = value.length === 0 ? "required" : "";
            break;
          case "adminuser":
            FormError.adminuser = value.length === 0 ? "required" : "";
            break;
          default:
            break;
        }
    
        setState((prevState) => ({
          ...prevState,
          FormError,
        }));
      };

    const onSubmit = async (e) => {
        e.preventDefault(); //prevent default
        requiredValid(state.userName, "userName");
        requiredValid(state.password, "password");
        requiredValid(state.adminuser, "adminuser");
        if (state.showCaptcha) {
            requiredValid(state.captchavalue, "captchavalue");
        }

        const formValid = (formError) => {
            let valid = true;
            Object.values(formError).forEach((val) => val.length > 0 && (valid = false));
            return valid;
          };
        const service = new LoginService();
        if (formValid(state.FormError)) {
            setState((prevState) => ({
                ...prevState,
                loader: true,
            }));
            service.getApi(state.adminuser).then(async (res) => {
                if (res.status) {
                    let cridential = {
                        username: state.userName,
                        password: state.password,
                    };
                    setState((prevState) => ({
                        ...prevState,
                        loader: false,
                    }));
                    await localStorage.setItem("apiKey", res.data.key);
                    UserStore.apiKey = res.key;
                    await service.LoginUser(cridential).then((res) => {
                        if (res.status) {
                            localStorage.setItem("adminname", state.adminuser)
                            localStorage.setItem("username", state.userName)
                            localStorage.setItem("timeout", false)
                            if (res.data.unapproved === true) {
                                console.log("i am unapproved")
                                setState((prevState) => ({
                                    ...prevState,
                                    unapproved: true,
                                    showCaptcha: false,
                                }));
                            }
                            // setState((prevState) => ({
                            //   ...prevState,
                            //   showCaptcha: false,
                            // }));
                            resetform();
                            localStorage.setItem(
                                "login",
                                JSON.stringify({
                                    token: res.data.access_token,
                                })
                            );
                            // let currencyData =  Curr.Currecy.find((obj)=>obj.code == res.data.currency.code);
                            // dispatch(changeCurr(currencyData.symbol_left,currencyData.value,currencyData.precision,currencyData.id))
                            localStorage.setItem("currency", res.data.currency.code);
                            localStorage.setItem("approved", res.data.approved)
                            UserStore.key = res.data.access_token;
                            UserStore.isLoggedIn = true;
                            setState((prevState) => ({
                                ...prevState,
                                isLogged: true,
                            }));
                            proPs.islogged();
                            localStorage.setItem("invalidlogin", 0);
                            // } else if (res.status.unapproved === true) {
                            //   setState((prevState) => ({
                            //     ...prevState,
                            //     isLogged: false,
                            //     unapproved: true,
                            //   }));
                            // } else {
                            if (res.data && res.data.code === 1055) {
                                let Counter = localStorage.getItem("invalidlogin")
                                    ? parseInt(localStorage.getItem("invalidlogin"))
                                    : 1;
                                if (Counter >= 3) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        isLoggedError: true,
                                        captcha: res.data.captcha,
                                        showCaptcha: true,
                                    }));
                                    // Counter = 0;
                                } else {
                                    Counter = Counter + 1;
                                }
                                localStorage.setItem("invalidlogin", Counter);
                            }
                            // setState((prevState) => ({
                            //     ...prevState,
                            //     isLoggedError: true,
                            // }));
                            proPs.history.push('/dashboard')
                        }
                        else {
                            setState((prevState) => ({
                                ...prevState,
                                isLoggedError: true,
                            }));
                        }
                    });
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        loader: false,
                    }));

                    if (res.error.code === 1042) {
                        setState((prevState) => ({
                            ...prevState,
                            isLoggedError: true,
                        }));
                    } else if (res.error.code === 1001) {
                        setState((prevState) => ({
                            ...prevState,
                            isLoggedError: true,
                        }));
                    }

                    else if (res.error.code == 1043) {
                        console.log("iam inside eerrros")
                        setState((prevState) => ({
                            ...prevState,
                            isLoggedError: true,
                        }));
                    }
                }
            });
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="mb-2">
                {state.isLoggedError ? (
                    <Alert dismissible variant="danger" onClose={handleAlertDismiss}>
                        {t("Login.Error.LoginError")}
                    </Alert>
                ) : (
                    ""
                )}

                <form id="login-form" style={{textAlign : "center",marginTop : "10%"}}>
                    {/* {(process.env.REACT_APP_STATUS === "DEMO" ||
                !params.adminusername) && (
                  <Forms
                    type="text"
                    inputClass={`${currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
                      }`}
                    name="adminuser"
                    change={onchange}
                    error={state.FormError.adminuser}
                    value={state.adminuser}
                  />
                )} */}
                    {/* <Forms
                type="text"
                name="userName"
                inputClass={`${currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
                  }`}
                change={onchange}
                error={state.FormError.userName}
                value={state.userName}
              /> */}
               <img style={{maxWidth : "80px"}} src={proPic} alt="No Image" />
              <div><strong>{localStorage.getItem("username")}</strong></div> 
               <div style={{display : "flex", justifyContent : "center", marginTop : "10px" }}>
                    <Forms
                        type="password"
                        name="password"
                        // inputClass={`${currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
                        //   }`}
                        change={onchange}
                        error={state.FormError.password}
                        value={state.password}
                        width={true}
                    />
                    
                    {/* {state.showCaptcha && (
                        <>
                            <React.Fragment>
                                <Form.Group style={{ textAlign: 'center', background: 'aliceblue', padding: '10px' }}

                                >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}

                                    />

                                </Form.Group>
                            </React.Fragment>
                        </>
                    )} */}
<div>
                    <button
                        style={{ width : "30px",height : "42px", backgroundColor : "#70a9ff", borderRadius : "0px 5px 5px 0" }}
                        onClick={onSubmit}
                        disabled={state.btnDisabled}
                    //    {...state}
                    ><i class="fa fa-sign-in" aria-hidden="true"></i></button>
                    </div>
                    </div>
                </form>
            </div>
        </>
    );



}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Timeout))
);